import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { BrowserRouter, Route } from 'react-router-dom';

import { datadogRum } from '@datadog/browser-rum';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import {
  AuthProvider,
  CognitoLogin,
  FeatureControl,
  FeatureControlProvider,
  LocaleProvider,
  LocalizedSwitch,
  ResourceProvider,
} from '@traveloka/ctv-core';
import config from '@traveloka/ctv-core/config';
import { useRemoveTrackerVisitId } from '@traveloka/ctv-core/tracking/useTracker';
import {
  Button,
  Icon,
  ThemeProvider,
  useTheme,
} from '@traveloka/web-components';

import DayIcon from '@traveloka/icon-kit-web/svg/light/ic_time_day_24px.svg';

import { AccountStatusProvider } from 'account-status/context/AccountStatusContext';
import { CreditLimitProvider } from 'credit-limit/context';
import RouterInterceptor from 'generic/RouterInterceptor';
import EmailLoginRedirect from 'generic/prelogin/EmailLoginRedirect';
import EmailRegisterRedirect from 'generic/prelogin/EmailRegisterRedirect';
import PrivateRoute from 'shared/auth/PrivateRoute';
import { SnackbarProvider } from 'shared/contexts/Snackbar/SnackbarContext';

import CompanyApp from './company/CompanyApp';
import ContactUs from './contact-us/ContactUs';
import FlightApp from './flight/FlightApp';
import Home from './generic/Home';
import RedirectHome from './generic/RedirectHome';
import HotelApp from './hotel/HotelApp';
import InvoiceDispute from './invoice-dispute/InvoiceDispute';
import PaymentApp from './payment/PaymentApp';
import ProfileApp from './profile/ProfileApp';
import RedirectApp from './redirect/RedirectApp';
import RefundApp from './refund/RefundApp';
import Registration from './registration/Registration';
import TermsAndConditions from './terms-and-conditions/TermsAndConditions';

const queryCache = new QueryCache();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  },
  queryCache,
});

export default function App() {
  const [featureControl, setFeatureControl] = useState<FeatureControl>();

  useRemoveTrackerVisitId();

  useEffect(() => {
    try {
      if (config.datadog.env === 'prod') {
        datadogRum.init(config.datadog);
      }
    } catch (error) {
      // No op.
    }
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <LocaleProvider>
          <QueryClientProvider client={queryClient}>
            <FeatureControlProvider
              featureControl={featureControl}
              setFeatureControl={setFeatureControl}
            >
              <ResourceProvider>
                <ThemeProvider>
                  <AccountStatusProvider>
                    <CreditLimitProvider>
                      <SnackbarProvider>
                        <RouterInterceptor>
                          <LocalizedSwitch>
                            <Route path="/" exact component={Home} />
                            <Route
                              path="/register"
                              exact
                              component={Registration}
                            />
                            {/* TODO: Move both these email register to `/redirect` */}
                            <Route
                              path="/email-register-redirect"
                              exact
                              component={EmailRegisterRedirect}
                            />
                            <Route
                              path="/email-login-redirect"
                              exact
                              component={EmailLoginRedirect}
                            />
                            <Route path="/redirect" component={RedirectApp} />
                            <PrivateRoute
                              path="/flight"
                              component={FlightApp}
                            />
                            <PrivateRoute path="/hotel" component={HotelApp} />
                            <PrivateRoute
                              path="/payment"
                              component={PaymentApp}
                            />
                            <PrivateRoute
                              path="/company"
                              component={CompanyApp}
                            />
                            <PrivateRoute
                              path="/refund"
                              component={RefundApp}
                            />
                            <PrivateRoute
                              path="/profile"
                              component={ProfileApp}
                            />
                            <PrivateRoute
                              path="/contact-us"
                              component={ContactUs}
                            />
                            <PrivateRoute
                              path="/invoice-dispute"
                              component={InvoiceDispute}
                            />
                            <Route
                              path="/terms-and-conditions"
                              component={TermsAndConditions}
                            />
                            <Route path="/login" component={CognitoLogin} />
                            <Route component={RedirectHome} />
                          </LocalizedSwitch>
                        </RouterInterceptor>
                      </SnackbarProvider>
                    </CreditLimitProvider>
                    <ThemeSwitcher />
                  </AccountStatusProvider>
                </ThemeProvider>
              </ResourceProvider>
            </FeatureControlProvider>
          </QueryClientProvider>
        </LocaleProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

function ThemeSwitcher() {
  const { set, name } = useTheme();

  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  return (
    <View
      // @ts-ignore
      style={{
        position: 'fixed',
        right: 24,
        bottom: 24,
      }}
    >
      <Button
        onPress={() => set(name === 'dark' ? 'default' : 'dark')}
        variant="circular-primary"
        iconStart={() => <Icon src={DayIcon} width={24} height={32} />}
      />
    </View>
  );
}
